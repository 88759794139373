import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOktaAuth} from '@okta/okta-react';
import {useLocation, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import {AccountStatus, setOktaAuthState, setOktaAuth, logout, oktaLoginUser, setUserId, setIsLoggingIn} from './../store/Network';
import {connectToWebsocket} from './../store/socket';
import {getSettings, getFacilities, saveUserReduxState, initializeUser, getLists} from './../store/User';
import {loadModalities} from './../store/System';
import {loadUserLegacyCart} from './../store/Cart';
import {getCookie, logEvent, translateHomeScreen} from 'utility';
import {oktaSignInConfig} from '../oktaConfig';

const OKTA_OAUTH2_NAME = process.env.REACT_APP_OAUTH2_NAME;

const AuthHandler = ({sessionStorageAvailable, triggerLogout, updateTriggerLogout}) => {
    const {oktaAuth, authState} = useOktaAuth();
    
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const dashboard = useSelector(state => state.system.configuration.features.dashboard);
    const notifications = useSelector(state => state.system.configuration.features.notifications);
    const subscriptionId = useSelector(state => state.network.tokenInfo.subscriptionId);
    const isImpersonating = useSelector(state => state.network.isImpersonating);
    const tokenInfoUserId = useSelector(state => state.network.tokenInfo.userId);
    const adminIsLoggedIn = useSelector(state => state.network.admin.isLoggedIn);
    const authStateStore = useSelector(state => state.network.authState);
    const oktaAuthentication = useSelector(state => state.system.siteSettings.oktaAuthentication);
    const [localOktaAuthentication, setLocalOktaAuthentication] = useState(false);

    useEffect(() => {
        setLocalOktaAuthentication(oktaAuthentication);
    }, [oktaAuthentication]);

    useEffect(() => {
        const callLogin = async (accessToken, idToken, refreshToken) => {
            await login(accessToken, idToken, refreshToken)
            dispatch(setIsLoggingIn(false));
        }
        if (localOktaAuthentication && !Cookies.get('impersonate') && !Cookies.get('subscriptionId')) {
            let shouldTriggerLogin = false;
            if (!authStateStore && authState) {
                shouldTriggerLogin = true;
            }

            if (authState !== null) {
                dispatch(setOktaAuthState(authState));
            }

            const token = Cookies.get('token');

            let access_token = Cookies.get('access_token');
            let id_token = Cookies.get('id_token');
            let refresh_token = Cookies.get('refresh_token');
            let date = Cookies.get('expires_at');
            let expires_at = Date.parse(date);
            let scope = Cookies.get('scope');
            let token_type = Cookies.get('token_type');

            if (!authState && access_token && id_token && refresh_token && expires_at && scope) {           
                let oToken = {expiresAt: expires_at, scope: scope.split(' ')};

                let accessToken = Object.assign({},oToken);
                let decodedAccess = oktaAuth.token.decode(access_token);
                accessToken.claims = decodedAccess.payload;
                accessToken.accessToken = access_token;
                accessToken.tokenType = token_type;
                accessToken.userinfoUrl = `${oktaSignInConfig.baseUrl}/oauth2/${OKTA_OAUTH2_NAME}/v1/userinfo`;

                let idToken = Object.assign({},oToken);
                let decodedId = oktaAuth.token.decode(id_token);
                idToken.claims = decodedId.payload;
                idToken.idToken = id_token;
                
                let refreshToken = Object.assign({},oToken);
                refreshToken.refreshToken = refresh_token;

                let tokens = {'accessToken': accessToken,
                    'idToken': idToken,
                    'refreshToken': refreshToken};

                oktaAuth.tokenManager.setTokens(tokens);
            }

            if (authState && authState.isAuthenticated && (shouldTriggerLogin || !token || isImpersonating || (tokenInfoUserId === 0 && adminIsLoggedIn === false))) {
                if (!authState.accessToken || !authState.accessToken.claims || !Number.isInteger(authState.accessToken.claims.status)) {
                // if (!authState.accessToken || !authState.accessToken.claims) {
                    logUserOut();
                    return;
                }

                callLogin(authState.accessToken, authState.idToken, authState.refreshToken);
            } else if (authState && !authState.isAuthenticated) {
                dispatch(setIsLoggingIn(false));         
            }
        }
    }, [authState, localOktaAuthentication]);

    useEffect(() => {
        dispatch(setOktaAuth(oktaAuth));
    }, [oktaAuth]);

    useEffect(() => {
        if (location.pathname === '/logout' && oktaAuth) {
            logUserOut();
        }
    }, [oktaAuth, location.pathname]);

    useEffect(() => {
        if (triggerLogout) {
            oktaAuth.tokenManager.clear();
            oktaAuth.signOut().then(() => {
                dispatch(logout());
                history.push('/');
                if (updateTriggerLogout) {
                    updateTriggerLogout(false);
                }
                // Remove Okta SSO cookies
                Cookies.remove('access_token');
                Cookies.remove('id_token');
                Cookies.remove('refresh_token');
                Cookies.remove('expires_at');
                Cookies.remove('scope');
                Cookies.remove('token_type');
            });
        }
    }, [triggerLogout])

    const login = async (accessToken, idToken, refreshToken) => {
        // dispatch(setIsLoggingIn(true));
        const info = await oktaAuth.token.getUserInfo(accessToken, idToken);
        await dispatch(oktaLoginUser(info, accessToken, refreshToken));
        // await dispatch(resetPath());

        if (sessionStorageAvailable)
            sessionStorage.removeItem('fields');

        Cookies.remove('browsingHistory');
        const {ak1, ak2, apolloAdminRole, firstName, lastName} = accessToken.claims;
        const status = parseInt(accessToken.claims.status);
        const userId = parseInt(accessToken.claims.userId);
        const rootCompanyId = parseInt(accessToken.claims.rootCompanyId);
        const isEmployee = accessToken.claims.isEmployee;
        const impersonating = accessToken.claims.isImpersonating;

        if (status) {
            if (window.appInsights) {
                window.appInsights.setAuthenticatedUserContext(userId.toString(), rootCompanyId.toString(), true);
                window.appInsights.context.user.id = userId.toString();
            }
        }

        if (isEmployee) {

            if (apolloAdminRole === '4')
                finishLoading('', null, null, '/admin/rfq');
            else
                finishLoading('', null, null, '/admin/company');

            return;
        }

        dispatch(initializeUser());
        dispatch(getLists());
        dispatch(loadModalities())

        // TODO: If the user needs to accept the EULA or change password,
        // none of the other things in the login flow will have happened.
        // (no settings loaded)
        if (status & AccountStatus.EulaRequired) { // || status & AccountStatus.PasswordExpired) {   // Password expired should be handled by the widget
            const settings = await dispatch(getSettings());
            const user = settings.response.data.settings;
            const {url} = settings;

            dispatch(loadUserLegacyCart());
            const facilitiesResponse = await dispatch(getFacilities());
            if (facilitiesResponse.response && facilitiesResponse.response.data) {
                let defaultFacility = _.find(facilitiesResponse.response.data, f => _.toNumber(f.facilityId) === _.toNumber(user.defaultFacilityId));

                if (!defaultFacility && facilitiesResponse.response.data.length === 1)
                    defaultFacility = facilitiesResponse.response.data[0];

                if (defaultFacility) {
                    dispatch(saveUserReduxState({facility: defaultFacility}));
                }
            }

            if (status & AccountStatus.EulaRequired) {
                finishLoading(url, ak1, ak2, '/policies-acceptance');
            }

            finishLoading('', null, null, '/');
            return;
        }

        if (status === AccountStatus.Valid) {
            const settings = await dispatch(getSettings());
            const user = settings.response.data.settings;
            const {url} = settings;
            const {cookiePolicyAccepted, eulaRequired} = settings.response.data.userInfo;

            dispatch(setUserId(userId, firstName, lastName, subscriptionId, false, eulaRequired));

            if (dashboard && notifications && user.hasDashboardv2Access) {
                dispatch(connectToWebsocket());
            }

            if (user.hasReportsAccess)
                axios.post('/AuthenticationService/api/v1.0/sisense/initiate');

            dispatch(loadUserLegacyCart());
            const facilitiesResponse = await dispatch(getFacilities());
            if (facilitiesResponse.response && facilitiesResponse.response.data) {
                let defaultFacility = _.find(facilitiesResponse.response.data, f => _.toNumber(f.facilityId) === _.toNumber(user.defaultFacilityId));

                if (!defaultFacility && facilitiesResponse.response.data.length === 1)
                    defaultFacility = facilitiesResponse.response.data[0];

                if (defaultFacility) {
                    dispatch(saveUserReduxState({facility: defaultFacility}));
                }

                if (isImpersonating) {
                    finishLoading('', ak1, ak2, '/');
                }
                // After user is loaded, if privacy policy has changed, ask the user to either accept it ot
                if (eulaRequired && !impersonating) {
                    finishLoading(url, ak1, ak2, '/policies-acceptance');
                    return;
                }

                if (!impersonating && sessionStorageAvailable && sessionStorage.prevUrl && sessionStorage.prevUrl !== '/' && sessionStorage.prevUrl !== '/login' && sessionStorage.prevUrl !== '/changepassword') {
                    finishLoading('', null, null, sessionStorage.prevUrl);
                } else {
                    finishLoading(url, ak1, ak2, translateHomeScreen(user.defaultHomeScreen));
                    trackLogin(status);
                    return;
                }
            }
        } 
        
        finishLoading('', null, null, '/');
    };

    const getCookieAcceptanceValue = () => {
        const cookieName = 'cookie-policy';

        if (getCookie(cookieName))
            return true;
        else
            return false;
    };

    const trackLogin = (status) => {
        logEvent('LOGIN', {
            'Login': status === 200 ? 'true' : 'false',
            'redirect': location.href,
        });
    };

    const finishLoading = (url, ak1, ak2, redUrl) => {      
        if (isImpersonating) {
            history.push('/');
        } else if (redUrl && redUrl !== '/') {
            redirect(url, ak1, ak2, redUrl);
        }
    };

    const redirect = (url, ak1, ak2, pathname) => {
        if (url !== '' && window.location.hostname !== 'localhost' && window.location.href.indexOf('partssource.com') === -1)
            window.location.replace(url + pathname + '?ak1=' + ak1 + '&ak2=' + ak2);
        else
            history.push(pathname)

        return;
    };

    const logUserOut = () => {
        oktaAuth.tokenManager.clear();
        oktaAuth.signOut().then(() => dispatch(logout()));
    }

    return null;
};

export default AuthHandler;
