import React from 'react';
import styled, {keyframes} from 'styled-components';

const primary = '#FF9505';
const grey = '#F1F1F1';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Loading = styled.div`
    z-index: 1000;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255, 0.8);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

const Loader = styled.div`
    top: calc(50% - 37.5px) !important;
    left: calc(50% - 37.5px) !important;
    border: 10px solid ${grey};
    border-radius: 50%;
    border-top: 10px solid ${primary};
    width: 75px;
    height: 75px;
    animation: ${rotate} 2s linear infinite;
    margin-top: 30px;
    display : block;
    position : fixed;
`;

const Spinner = () => {
    return (
        <Loading>
            <Loader />
        </Loading>
    );
}

export default Spinner;
