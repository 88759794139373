import React, {useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import Loadable from 'react-loadable';
import * as PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Security} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';

import {setIsLoggingOut} from './../store/Network';
import {oktaAuthConfig} from '../oktaConfig';
import Spinner from './../components/Spinner';
import AuthHandler from './../components/AuthHandler';
import {AuthHandlerProvider} from '../contexts/AuthHanderContext';

import '@okta/okta-auth-js/polyfill';

const oktaAuth = new OktaAuth(oktaAuthConfig);

function Loading(props) {
    const {error} = props;
    
    if (error && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.string,
};

const Layout = Loadable({loader: () => import(/* webpackChunkName: "Layout" */'pages/Layout'), loading: Loading});
const AdminLayout = Loadable({loader: () => import(/* webpackChunkName: "AdminLayout" */'pages/Admin/AdminLayout'), loading: Loading});
const BlankLayout = Loadable({loader: () => import(/* webpackChunkName: "BlankLayout" */'pages/BlankLayout'), loading: Loading});
const BadRoute = Loadable({loader: () => import(/* webpackChunkName: "BadRoute" */'pages/BadRoute'), loading: Loading});

const MasterLayout = () => {
    const history = useHistory();

    const customAuthHandler = () => {
        //history.push('/login');
    };
  
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        if (originalUri)
            history.replace(toRelativeUrl(originalUri, window.location.origin))  
            else
            history.replace('/');
        };

    return (
        <Security   
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <AuthHandlerProvider>
                <Switch>
                    <Route path="/admin" render={() => <AdminLayout />}/>
                    <Route path="/sso/" component={BlankLayout}/>
                    <Route path="*" render={() => <Layout/>}/>
                    <Route component={BadRoute}/>
                </Switch>             
            </AuthHandlerProvider>
        </Security>
    );
};

export default MasterLayout;
