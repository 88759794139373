const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const OKTA_OAUTH2_NAME = process.env.REACT_APP_OAUTH2_NAME;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = '/login/callback';

const PROTOCOL = ENVIRONMENT === 'local' ? 'http' : 'https';
const ISSUER = `https://${OKTA_DOMAIN}/oauth2/${OKTA_OAUTH2_NAME}`;
const BASE_URL = `https://${OKTA_DOMAIN}`;
const HOST = window.location.host;
const REDIRECT_URI = `${PROTOCOL}://${HOST}${CALLBACK_PATH}`;
const SCOPES = 'openid profile email offline_access';

const oktaAuthConfig = {
    // Note: If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to add `pkce: false`
    issuer: ISSUER,
    clientId: CLIENT_ID,
    //redirectUri: REDIRECT_URI,
    scopes: SCOPES.split(/\s+/),
    devMode: true,
};
  
const oktaSignInConfig = {
    baseUrl: BASE_URL,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    useInteractionCodeFlow: true,
    useClassicEngine: false,
    authParams: {
        // If your app is configured to use the Implicit flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        // pkce: false
        scopes: SCOPES.split(/\s+/),
        issuer: ISSUER,
        pkce: true,
    },
    // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
    i18n: {
        'en': {
            'primaryauth.title': 'Sign In',
            'primaryauth.username.placeholder': 'Email or Username',
            'primaryauth.password.placeholder': 'Password',
            'password.forgot.email.or.username.placeholder': 'Email or Username',
            'password.forgot.email.or.username.tooltip': 'Email or Username',
            'account.unlock.email.or.username.placeholder': 'Email or username',
            'account.unlock.email.or.username.tooltip': 'Email or username'
        },
    },
};
  
export {oktaAuthConfig, oktaSignInConfig};
